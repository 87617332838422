import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  SvgIcon,
  Grid,
} from '@mui/material';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import KombitLocationTree from './KombitLocationTree';
import { cloneDeep } from 'lodash';
import { useFetchCustomer } from 'requests/hooks/useFetchCustomer';
import { KombitLocations, useFetchKombitCustomer } from 'requests/hooks/useFetchKombitCustomer';
import LoadingScreen from 'Components/LoadingScreen';
import { Search as SearchIcon } from '@mui/icons-material';
import SaveDrawer from 'Components/SaveDrawer';
import { deepEqual } from 'fast-equals';
import useUpdateLocationExternalIds, {
  UpdateExternalIdsMutationRequest,
} from 'requests/hooks/useUpdateLocationExternalIds';
import { Customer, UpdateExternalIdsRequest } from 'requests/nswag/atlas/AtlasClient';
import { useSnackbar } from 'notistack';

const KombitView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const customer = useCustomerContext();
  const theme = useTheme();
  const {
    data: sekoiaCustomerData,
    status: sekoiaLocationStatus,
    fetchStatus: sekoiaLocationFetchStatus,
  } = useFetchCustomer(customer.id);
  const {
    data: kombitCustomerTree,
    status: kombitStatus,
    isLoading: kombitIsLoading,
  } = useFetchKombitCustomer(sekoiaCustomerData?.externalId ?? '');
  const updateLocationExternalIds = useUpdateLocationExternalIds();
  const [sekoiaLocations, setSekoiaLocations] = useState<Customer>();
  const [initialLocations, setInitialLocations] = useState<Customer>();
  const [searchString, setSearchString] = useState<string>('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (sekoiaCustomerData) {
      setInitialLocations(sekoiaCustomerData);
      setSekoiaLocations(sekoiaCustomerData);
    }
  }, [sekoiaCustomerData]);

  useEffect(() => {
    const hasChanges = !deepEqual(initialLocations, sekoiaLocations);
    setHasChanges(hasChanges);
  }, [initialLocations, sekoiaLocations]);

  const onSelectedLocation = useCallback(
    (sekoiaLocationId: string, kombitLocationId: string) => {
      const sekoiaLocationsCopy = cloneDeep(sekoiaLocations);
      const sekoiaLocationsMappedToKombitId = sekoiaLocations?.organisations.filter((org) =>
        org.externalIds.includes(kombitLocationId),
      );

      //clean all locations mapped to this kombitLocationId
      if (sekoiaLocationsMappedToKombitId && sekoiaLocationsMappedToKombitId.length > 0) {
        sekoiaLocationsCopy?.organisations.forEach((location) => {
          if (sekoiaLocationsMappedToKombitId.some((ml) => ml.id === location.id)) {
            const filteredExternalIds = location.externalIds.filter((ex) => ex !== kombitLocationId);
            location.externalIds = filteredExternalIds;
          }
        });
      }

      if (sekoiaLocationId !== '') {
        const indexOfSekoiaLocation = sekoiaLocationsCopy?.organisations.findIndex((sl) => sl.id === sekoiaLocationId);
        if (sekoiaLocationsCopy && indexOfSekoiaLocation !== undefined) {
          sekoiaLocationsCopy.organisations[indexOfSekoiaLocation].externalIds.push(kombitLocationId);
        }
      }
      setSekoiaLocations(sekoiaLocationsCopy);
    },
    [sekoiaLocations],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const searchFilteredLocations: KombitLocations[] = useMemo(() => {
    if (!kombitCustomerTree?.OrganisationTree) return [];
    if (searchString === '') return kombitCustomerTree?.OrganisationTree.Children;
    const searchForElements = (locations: KombitLocations[]) => {
      return locations.flatMap((location: KombitLocations) => {
        const locationIsMatch = location.Name.toLowerCase().includes(searchString.toLowerCase());

        if (locationIsMatch && location.Children.length === 0) return location;

        const matchedChildren = searchForElements(location.Children);

        if (locationIsMatch || matchedChildren.length > 0) {
          const returnObj: KombitLocations[] = [{ ...location, Children: matchedChildren }];
          return returnObj;
        } else {
          return [];
        }
      });
    };
    const searched = searchForElements(kombitCustomerTree?.OrganisationTree.Children);

    return searched;
  }, [searchString, kombitCustomerTree]);

  const handleSaveMapping = async () => {
    setIsSaving(true);
    const locations: UpdateExternalIdsRequest[] =
      sekoiaLocations?.organisations.map(
        (org) => new UpdateExternalIdsRequest({ globalId: org.id, externalIds: org.externalIds }),
      ) ?? [];
    const request: UpdateExternalIdsMutationRequest = {
      request: locations,
      customerId: customer.id,
    };
    await updateLocationExternalIds.mutate(request);
  };

  useEffect(() => {
    if (updateLocationExternalIds.isSuccess) {
      enqueueSnackbar('Successfully updated mapping of locations', { variant: 'success' });
      setIsSaving(false);
    }
  }, [enqueueSnackbar, updateLocationExternalIds.isSuccess]);

  useEffect(() => {
    if (updateLocationExternalIds.isError) {
      enqueueSnackbar('Something went wrong while updating mapping of locations', {
        variant: 'error',
      });
      setIsSaving(false);
    }
  }, [enqueueSnackbar, updateLocationExternalIds.isError, updateLocationExternalIds.isSuccess]);

  const handleCancel = () => {
    setSekoiaLocations(initialLocations);
  };

  if (kombitIsLoading || sekoiaLocationFetchStatus === 'fetching') {
    return <LoadingScreen />;
  }

  if (kombitStatus === 'error' || sekoiaLocationStatus === 'error') {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 'calc(100vh - 64px)' }}
      >
        <Typography align="center" variant={'h6'} color="textPrimary">
          Could not load kombit customer hierarchy
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Box m={2}>
        <Box mb={2}>
          <Typography variant="h5" component="span">
            Kombit - {customer.name}
          </Typography>
        </Box>
        <Paper>
          <Box p={2} sx={{ borderBottom: '1px solid', borderColor: theme.palette.divider }}>
            <TextField
              autoFocus
              fullWidth
              defaultValue={''}
              placeholder={'Search for location'}
              variant={'outlined'}
              onChange={handleSearchChange}
              sx={{ maxWidth: 500 }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 314px)' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Kombit location</TableCell>
                  <TableCell variant="head">Mapped to Sekoia location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchFilteredLocations.map((location) => {
                  return (
                    <KombitLocationTree
                      kombitLocation={location}
                      indent={1}
                      key={location.Id}
                      customerId={customer.id}
                      onSelectedLocation={onSelectedLocation}
                      sekoiaLocations={sekoiaLocations}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box p={4}>
        <SaveDrawer
          open={true}
          onSave={handleSaveMapping}
          isSaving={isSaving}
          disableSaveButton={!hasChanges}
          modified={hasChanges}
          onCancel={handleCancel}
        />
      </Box>
    </>
  );
};

export default KombitView;
