import {
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputAdornment,
  TextField,
  Dialog,
  IconButton,
} from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useCustomerContext } from 'requests/hooks/useCustomerContext';
import { useFetchPlatformsList, Platform } from 'requests/hooks/useFetchPlatformsList';

function PlatformsView() {
  const customer = useCustomerContext();
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [open, setOpen] = useState(false);
  const { data } = useFetchPlatformsList(customer.id, customer.compassUrl);
  const [details, setDetails] = useState<Platform>();

  useEffect(() => {
    if (data) setPlatforms(data);
  }, [data]);

  if (!data) return null;

  function filterResidents(searchValue: string) {
    if (!data) return;

    if (searchValue.length === 0) {
      setPlatforms(data);
      return;
    }

    const filteredPlatforms = data.filter(
      (x) =>
        x.karunaConfiguration?.profiles.find((x) => x.fullName.toLowerCase().indexOf(searchValue) >= 0) ||
        x.karunaConfiguration?.apartmentDisplayName?.toLowerCase().indexOf(searchValue) >= 0,
    );
    setPlatforms(filteredPlatforms);
  }

  function openDetailsDialog(platform: Platform) {
    setDetails(platform);
    setOpen(true);
  }

  function detailsDialog() {
    if (!details) return;
    return (
      <Dialog open={open}>
        <Box m={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ fontSize: 32 }}>Details</Typography>
          <IconButton style={{ padding: 0 }} size="large">
            <Close onClick={() => setOpen(false)} />
          </IconButton>
        </Box>
        <Box m={2}>
          <Typography style={{ fontSize: 16 }}>MAC addresses</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.addresses.map((address, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">{address.name}</TableCell>
                      <TableCell align="left">{address.value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
    );
  }

  return (
    <Box m={2}>
      <Box mb={4}>
        <Typography style={{ fontSize: 32 }}>Platforms</Typography>
        <TextField
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => filterResidents(event.target.value.toLowerCase())}
          label="Find residents or apartments"
          variant="outlined"
          sx={{ marginTop: 2 }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            },
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Apartment</TableCell>
              <TableCell align="left">Splashtop name</TableCell>
              <TableCell align="left">Machine id</TableCell>
              <TableCell align="left">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {platforms.map((row) => {
              return (
                <>
                  <TableRow style={{ background: '	#F0F0F0' }} key={row.id}>
                    <TableCell align="left" variant={'head'}>
                      {row.karunaConfiguration?.apartmentDisplayName
                        ? row.karunaConfiguration.apartmentDisplayName
                        : '-'}
                    </TableCell>
                    <TableCell align="left" variant={'head'}>
                      {row.id.substring(0, 15)}
                    </TableCell>
                    <TableCell align="left" variant={'head'}>
                      {row.id}
                    </TableCell>
                    <TableCell align="left" variant={'head'}>
                      {row.addresses.length > 0 && (
                        <Button
                          onClick={() => openDetailsDialog(row)}
                          style={{ paddingLeft: 0, justifyContent: 'flex-start' }}
                        >
                          Show
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  {row.karunaConfiguration &&
                    row.karunaConfiguration.profiles.map((resident, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell />
                          <TableCell align="left">{resident.fullName}</TableCell>
                          <TableCell align="left">{resident.globalId}</TableCell>
                          <TableCell />
                        </TableRow>
                      );
                    })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {detailsDialog()}
    </Box>
  );
}

export default PlatformsView;
