import React, { ChangeEvent } from 'react';
import { debounce, InputAdornment, SvgIcon, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

type FormsSearchProps<T, K extends Extract<keyof T, string>> = {
  forms: T[];
  onFormsFiltered: (val: T[]) => void;
  searchKey: K;
};

function FormsSearch<T, K extends Extract<keyof T, string>>({
  forms,
  onFormsFiltered,
  searchKey,
}: FormsSearchProps<T, K>) {
  const handleFilteringForms = debounce((searchText: string) => {
    if (searchText === '') {
      onFormsFiltered(forms);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = forms?.filter((f) => (f[searchKey] as any).toLowerCase().includes(searchText.toLowerCase()));
    onFormsFiltered(result);
  }, 250);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleFilteringForms(event.target.value);
  };

  return (
    <TextField
      fullWidth
      autoFocus
      style={{ maxWidth: 500 }}
      onChange={onChangeHandler}
      placeholder={'Search forms'}
      variant="outlined"
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}

export default FormsSearch;
